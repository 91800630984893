import React, { useEffect, useState } from "react";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/input";
import { personIcon, plus, search } from "resources/images/images";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import { addSellerApi } from "networking/apis/seller/addseller";
import { useAuthContext } from "hooks/useAuthContext";
import { editSellerApi } from "networking/apis/seller/editseller";
const AddSeller = () => {
    const navigate = useNavigate();
    const { storeData, isSellerEdit } = useAuthContext();
    const location = useLocation();
    const sellerAndProductDetails = location.state;
    // console.log("sellerAndProductDetails---", sellerAndProductDetails);
    // const [seller, setSeller] = useState("");
    // const [sellerId, setSellerId] = useState("");
    // const [phone, setPhone] = useState("");
    // const [email, setEmail] = useState("");
    // const [bank, setBank] = useState("");
    // const [registrationNumber, setRegistrationNumber] = useState("");
    // const [comment, setComment] = useState("");
    const [actionLoading, setActionLoading] = useState(false);
    const [editMessage, setEditMessage] = useState();
    const [addSeller, setAddSeller] = useState();

    const saveSellerSchema = Yup.object().shape({
        seller_name: Yup.string().required("seller name is required"),
        seller_id: Yup.string(),
        phone_number: Yup.string().required("Phone number required"),
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),
        bank_account: Yup.string().required("bank name is required"),
        reg_number: Yup.string().required("Registration number is required"),
        comment: Yup.string().required("Comment is required"),
    });

    // add seller

    const handleSaveSeller = async (values, { resetForm }) => {
        try {
            let addSellerData = { ...values };
            delete addSellerData.seller_id;
            Object.assign(addSellerData, {
                store_id: storeData.store._id,
            });
            console.log("addSellerData", addSellerData);
            const addSellerResponse = await addSellerApi(addSellerData);
            if (addSellerResponse.data.type === "success") {
                console.log("addSellerResponse", addSellerResponse);
                setAddSeller(alert(addSellerResponse.data.message));
                console.log("Dataa", values);
                setActionLoading(true);
                setTimeout(() => {
                    setActionLoading(false);
                    navigate(-1);
                }, 500);
            }
        } catch (error) {
            setActionLoading(false);
        }
    };

    // edit seller

    const handleEditSeller = async (values) => {
        try {
            let editData = { ...values };
            Object.assign(editData, {
                seller_id: sellerAndProductDetails?._id,
                store_id: sellerAndProductDetails?.store_id,
            });

            const editSellerResponse = await editSellerApi(editData);
            if (editSellerResponse.data.type === "success") {
                console.log("editSellerResponse", editSellerResponse);
                navigate("/profile", { state: editSellerResponse.data.data });
            }
        } catch {
            console.log("error in edit seller response");
        }
    };

    const formik = useFormik({
        initialValues: {
            seller_name: sellerAndProductDetails?.seller_name || "",
            seller_id: sellerAndProductDetails?.seller_uid || "",
            phone_number: sellerAndProductDetails?.phone_number || "",
            email: sellerAndProductDetails?.email || "",
            bank_account: sellerAndProductDetails?.bank_account || "",
            reg_number: sellerAndProductDetails?.reg_number || "",
            comment: sellerAndProductDetails?.comment || "",
        },
        validationSchema: saveSellerSchema,
        onSubmit: sellerAndProductDetails ? handleEditSeller : handleSaveSeller,
        enableReinitialize: true,
    });

    const renderLeftSection = () => {
        return (
            <p className={styles.titleTextStyle}>
                {sellerAndProductDetails ? "Edit seller" : "Add new seller"}
            </p>
        );
    };

    // Search Seller sections
    const renderSellerSctions = () => {
        return (
            <div className={styles.sellerSectionViewStyle}>
                <div className={styles.sellerSubViewStyle}>
                    <div className={styles.eachSectionViewStyle}>
                        <Input
                            name="seller_name"
                            placeholder="Enter name..."
                            lableText="Seller name * (First and Last name)"
                            value={formik.values.seller_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.seller_name &&
                                formik.errors.seller_name
                                    ? formik.errors.seller_name
                                    : ""
                            }
                            lableTextStyle={styles.lableTextStyle}
                            customInputStyle={styles.customInputViewStyle}
                        />
                    </div>
                    <div className={styles.eachSectionViewStyle}>
                        <Input
                            name="seller_id"
                            placeholder="id ..."
                            value={formik.values.seller_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.seller_id &&
                                formik.errors.seller_id
                                    ? formik.errors.seller_id
                                    : ""
                            }
                            lableTextStyle={styles.lableTextStyle}
                            customInputStyle={styles.sellerIdViewStyle}
                            customInputViewStyle={styles.customInputViewStyle2}
                            customLeftIconViewStyle={
                                styles.customLeftIconViewStyle
                            }
                            leftIcon={personIcon}
                            customErrorTextStyle={styles.customErrorTextStyle}
                            disabled={sellerAndProductDetails}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderPhoneAndEmailSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.phoneAndEmailSectionViewStyle}>
                    <Input
                        name="phone_number"
                        placeholder="Enter phone number..."
                        lableText="Phone *"
                        value={formik.values.phone_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.phone && formik.errors.phone
                                ? formik.errors.phone
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                    <Input
                        name="email"
                        placeholder="Enter email..."
                        lableText="E-mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.email && formik.errors.email
                                ? formik.errors.email
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderOtherSections = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.otherLeftSectionStyle}>
                    <Input
                        name="bank_account"
                        placeholder="Enter bank..."
                        lableText="Bank *"
                        value={formik.values.bank_account}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.bank && formik.errors.bank
                                ? formik.errors.bank
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                    <Input
                        name="reg_number"
                        placeholder="Enter Reg number..."
                        lableText="Reg number *"
                        value={formik.values.reg_number}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.registrationNumber &&
                            formik.errors.registrationNumber
                                ? formik.errors.registrationNumber
                                : ""
                        }
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
                <div className={styles.eachSectionViewStyle}>
                    <TextArea
                        name="comment"
                        placeholder="Enter comment..."
                        lableText="Comment *"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        hasError={
                            formik.touched.comment && formik.errors.comment
                                ? formik.errors.comment
                                : ""
                        }
                        lableTextStyle={styles.commentlableTextStyle}
                        customInputStyle={styles.commentInputViewStyle}
                        customErrorTextStyle={styles.customErrorTextStyle}
                    />
                </div>
            </div>
        );
    };

    const renderBtnSection = () => {
        return (
            <div className={styles.saveBtnSectionViewStyles}>
                <Button
                    type="submit"
                    title={
                        actionLoading
                            ? "Loading ...."
                            : "Save seller" && sellerAndProductDetails
                            ? "EditSeller"
                            : "Save seller"
                    }
                    btnStyle={styles.saveBtnStyle}
                />
            </div>
        );
    };
    const renderFormSection = () => {
        return (
            <form
                onSubmit={formik.handleSubmit}
                className={styles.formViewStyle}
            >
                {renderSellerSctions()}
                {renderPhoneAndEmailSection()}
                {renderOtherSections()}
                {renderBtnSection()}
            </form>
        );
    };
    return (
        <div className={styles.containerStyle}>
            <div className={styles.leftSectionStyle}>
                {renderLeftSection()}
                {renderFormSection()}
            </div>
            <div className={styles.rightSectionStyle}>
                <Button
                    title="Back"
                    onClick={() => navigate(-1)}
                    btnStyle={styles.customBtnStyle}
                />
            </div>
        </div>
    );
};
export default AddSeller;
