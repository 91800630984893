import Button from "components/button";
import Input from "components/input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    personBlackIcon,
    personIcon,
    plus,
    search,
} from "resources/images/images";
import styles from "./style.module.css";
import { SellersData } from "resources/dummyData";
import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
const Sellers = () => {
    const { pathname } = useLocation();
    const navigation = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [allSellers, setAllSellers] = useState();
    const [filteredSellers, setFilteredSellers] = useState(allSellers);
    // const [sellerDetails, setSellerDetails] = useState();

    useEffect(() => {
        setFilteredSellers(allSellers);
    }, [allSellers]);

    const filterProduct = (e) => {
        setSearchText(e.target.value);
        setFilteredSellers(
            allSellers.filter((seller) =>
                JSON.stringify(seller)
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    // get  all seller details

    const handleGetAllSellerDeatils = async () => {
        try {
            const getAllSellersResponse = await getAllSellerDetailsApi();
            if (getAllSellersResponse) {
                // console.log("getAllSellersResponse", getAllSellersResponse);
                setAllSellers(getAllSellersResponse.data.data);
            }
        } catch {
            console.log("error in fetching sellers details");
        }
    };

    useEffect(() => {
        handleGetAllSellerDeatils();
    }, []);

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <div className={styles.inputSectionStyle}>
                    <Input
                        placeholder="Search here"
                        value={searchText}
                        onChange={(e) => filterProduct(e)}
                        customInputStyle={styles.inputStyle}
                        customInputViewStyle={styles.customInputViewStyle}
                        customRightIconViewStyle={
                            styles.customRightIconViewStyle
                        }
                        rightIcon={search}
                    />
                </div>
                <Button
                    title="Add Seller"
                    icon={plus}
                    onClick={() => navigation("/addSeller")}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.iDHeadingStyle}>ID</td>
                        <td className={styles.headingStyle2}>Name</td>
                        <td className={styles.headingStyle2}>Phone</td>
                        <td className={styles.headingStyle2}>E-mail </td>
                        <td className={styles.headingStyle2}>Created date</td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {filteredSellers &&
                        filteredSellers.map((item, index) => (
                            <tr
                                key={index}
                                className={styles.assetListDataRowStyle}
                            >
                                <td className={styles.serialNoStyle}>
                                    <p
                                        className={[
                                            styles.serialNoTextStyle,
                                            filteredSellers.length === 1 &&
                                                styles.serialNoTextStyle2,
                                        ].join(" ")}
                                    >
                                        {item.seller_uid}
                                    </p>
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.seller_name}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.phone_number}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.email}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {new Date(
                                        item.created_at
                                    ).toLocaleDateString()}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <Button
                                        title="Add new product"
                                        onClick={() =>
                                            navigation("/addProduct")
                                        }
                                        icon={plus}
                                        btnStyle={styles.editBtnViewStyle}
                                    />
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <Button
                                        title="View profile"
                                        onClick={() =>
                                            navigation("/profile", {
                                                state: item,
                                            })
                                        }
                                        icon={personBlackIcon}
                                        btnStyle={
                                            styles.viewProfileBtnViewStyle
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const renderTableSection = () => {
        return (
            <div className={styles.tableSectionViewStyle}>
                {renderTableTopSection()}
                <div className={styles.bottomTableViewStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerStyle}>
            {renderTopSection()}
            {renderTableSection()}
        </div>
    );
};
export default Sellers;
