import React, { createContext, useEffect, useState } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import { getCartProductsApi } from "networking/apis/cart/getcartproducts";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { useCartDataContext } from "hooks/useCartDataContext";
import { useSellerDataContext } from "hooks/useSellerDataContext";

export const CartDataContext = createContext();

export const CartDataProvider = (props) => {
    const [cartData, setCartData] = useState();
    const { storeData } = useAuthContext();
    // const { allSellers } = useSellerDataContext();
    // console.log("storeData", storeData);
    // console.log("cartData####", cartData);
    // console.log("allSellers%%%", allSellers);

    useEffect(() => {
        if (storeData) {
            handleGetCartProducts();
        }
    }, [storeData]);

    const handleGetCartProducts = async () => {
        // console.log("storeData", storeData);

        try {
            const getCartProductsResponse = await getCartProductsApi(
                storeData?.store?._id
            );
            // console.log("getCartProductsResponse", getCartProductsResponse);

            setCartData(getCartProductsResponse.data.data.items);
            // alert(getCartProductsResponse.data.message);
        } catch (error) {
            console.log("error", error.toString());
        }
    };

    const handleAddProductToCart = async (product) => {
        try {
            console.log("Selected Product:", product);
            let addProductToCartData = {
                store_id: product.store_id,
                product: product._id,
            };
            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                // setAddToCartData(addProductToCartResponse.data.data);
                alert(addProductToCartResponse.data.message);
                handleGetCartProducts();
                console.log(
                    "addProductToCartResponse",
                    addProductToCartResponse
                );
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <CartDataContext.Provider
            value={{ cartData, handleGetCartProducts, handleAddProductToCart }}
        >
            {props.children}
        </CartDataContext.Provider>
    );
};
