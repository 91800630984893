import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/input";
import Button from "../../components/button";
import { useAuthContext } from "hooks/useAuthContext";
import { loginApi } from "networking/apis/store/login";
import styles from "./style.module.css";
const Login = () => {
    const navigate = useNavigate();
    const [actionLoading, setActionLoading] = useState(false);
    const { setIsLoggedIn, isLoggedIn, storeLogin, errorMessage } =
        useAuthContext();
    // const [errorMessage, setErrorMessage] = useState(false);

    const signUpSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required"),
    });

    const handleLogin = async (values, { resetForm }) => {
        await storeLogin(values);
        resetForm();
        setActionLoading(true);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: signUpSchema,
        onSubmit: handleLogin,
    });

    const tapOnLogin = async () => {
        setIsLoggedIn(true);
        localStorage.setItem("loggedIn", true);
        navigate("/products");
    };
    const renderInputSection = () => {
        return (
            <div className={styles.inputSectionStyle}>
                <p className={styles.titleTextStyle}>Login</p>
                <div className={styles.inputSubSectionStyle}>
                    <div className={styles.emailAndPasswordInputStyle}>
                        <Input
                            name="email"
                            lableText={"Email"}
                            placeholder={"Enter your email"}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.email && formik.errors.email
                                    ? formik.errors.email
                                    : ""
                            }
                            customInputStyle={styles.emailInputStyles}
                        />
                        <Input
                            name="password"
                            lableText={"Password"}
                            placeholder={"Enter Password"}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            hasError={
                                formik.touched.password &&
                                formik.errors.password
                                    ? formik.errors.password
                                    : ""
                            }
                        />
                    </div>
                    {errorMessage && (
                        <p className={styles.erroMessageStyles}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        );
    };

    const renderForgetPasswordSection = () => {
        return (
            <div className={styles.fpSectionStyle}>
                <p
                    onClick={() => console.log("cc")}
                    className={styles.fpTextStyle}
                >
                    Forgotten password
                </p>
            </div>
        );
    };

    const renderButtonSection = () => {
        return (
            <div className={styles.btnSectionStyle}>
                <Button
                    type="submit"
                    title={actionLoading ? "Loading..." : "Login"}
                    // onClick={() => {
                    //   tapOnLogin();
                    // }}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <form
                onSubmit={formik.handleSubmit}
                className={styles.subContainerStyle}
            >
                {renderInputSection()}
                {renderForgetPasswordSection()}
                {renderButtonSection()}
            </form>
        </div>
    );
};
export default Login;
