import React, { createContext, useState, useMemo } from "react";

const AppDataContext = createContext();

const AppDataProvider = (props) => {
    const [screenName, setScreenName] = useState(null);
    const [sideBar, setSideBar] = useState(true);
    const [isProductEdit, setIsProductEdit] = useState(false);

    const memoizedValue = useMemo(
        () => ({
            screenName,
            setScreenName,
            sideBar,
            setSideBar,
            isProductEdit,
            setIsProductEdit,
        }),
        [
            screenName,
            setScreenName,
            sideBar,
            setSideBar,
            isProductEdit,
            setIsProductEdit,
        ]
    );

    return (
        <AppDataContext.Provider value={memoizedValue}>
            {props.children}
        </AppDataContext.Provider>
    );
};

export { AppDataContext, AppDataProvider };
