export const Brands = [
    { value: "Nike", label: "Nike" },
    { value: "Adidas", label: "Adidas" },
    { value: "Sony", label: "Sony" },
    { value: "Apple", label: "Apple" },
    { value: "Puma", label: "Puma" },
    // { value: "Other", label: "Other" },
];
export const Products_Categorys = [
    { value: "Shoe", label: "Shoe" },
    { value: "Bike", label: "Bike" },
    { value: "Mobile", label: "Mobile" },
    { value: "Watch", label: "Watch" },
    { value: "Bag", label: "Bag" },
];
export const Models = [
    { value: "AirJordan", label: "AirJordan" },
    { value: "Samba", label: "Samba" },
    { value: "Loafers", label: "Loafers" },
    { value: "Sneaker", label: "Sneaker" },
];
export const Colors = [
    { value: "Red", label: "Red" },
    { value: "Black", label: "Black" },
    { value: "Yellow", label: "Yellow" },
    { value: "Green", label: "Green" },
    { value: "Gray", label: "Gray" },
];
export const Materials = [
    { value: "Cotton", label: "Cotton" },
    { value: "Leather ", label: "Leather" },
    { value: "Silk", label: "Silk" },
];
export const Sizes = [
    { value: "30", label: "30" },
    { value: "32", label: "32" },
    { value: "28", label: "28" },
    { value: "7", label: "7" },
    { value: "6", label: "6" },
];
export const ProductsData = [
    {
        id: "22.3",
        brand: "Nike",
        category: "Shoe",
        model: "AirJordan",
        material: "Cotton",
        color: "White",
        size: "38",
        price: "500DKK",
        status: "In store",
        createddata: "22.03.2023",
    },
    // {
    //     id: "11.1",
    //     brand: "Adidas",
    //     category: "Laptop",
    //     model: "Samba",
    //     material: "Cotton",
    //     color: "Red",
    //     size: "36",
    //     price: "800DKK",
    //     status: "Sold",
    //     createddata: "12.03.2023",
    // },
    // {
    //     id: "33.2",
    //     brand: "Filippa K",
    //     category: "Bag",
    //     model: "Loafers",
    //     material: "Leather",
    //     color: "Blue",
    //     size: "32",
    //     price: "450DKK",
    //     status: "In store",
    //     createddata: "01.01.2023",
    // },
];
export const SellersData = [
    {
        id: "22",
        name: "Anna Ericsson",
        phone: "12 66 88 22",
        email: "anna.e@gmail.com",
        createddata: "22.03.2023",
    },
    {
        id: "121",
        name: "Emma Pålssson",
        phone: "676575490",
        email: "vivek.kotha99@gmail.com",
        createddata: "12.03.2023",
    },
    {
        id: "14",
        name: "Petra Johansson",
        phone: "909876569",
        email: "rajashekar22@yopmail.com",
        createddata: "01.01.2023",
    },
];
