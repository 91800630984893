import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
    logout,
    logoutImg,
    personBlackIcon,
    personIcon,
    plus,
    shopping,
} from "resources/images/images";
import Modal from "react-modal";
import styles from "./style.module.css";
import Button from "components/button";
import Cart from "components/cart/cart";
import { useAuthContext } from "hooks/useAuthContext";
import { useCartDataContext } from "hooks/useCartDataContext";

const DashboardLayout = (props) => {
    const navigation = useNavigate();
    const { pathname } = useLocation();
    const { cartData } = useCartDataContext();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const { isLoggedIn, setIsLoggedIn, logout } = useAuthContext();
    // console.log("cartData@@@", cartData);

    let Data = [
        {
            title: "Product",
            route: "/products",
            route2: "/addProduct",
        },
        {
            title: "Seller",
            route: "/sellers",
            route2: "/addSeller",
            route3: "/profile",
        },
        {
            title: "Report",
            route: "/reports",
        },
    ];

    const onTabClick = (data) => {
        navigation(data.route);
    };

    const tapOnLogOut = () => {
        logout();
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <div className={styles.topSectionSubStyle}>
                    <div className={styles.tabSectionStyle}>
                        {Data.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => onTabClick(item)}
                                className={[
                                    (pathname === item.route ||
                                        pathname === item.route2 ||
                                        pathname === item.route3) &&
                                        styles.activeTabBgColor,
                                    styles.tabViewStyle,
                                ].join(" ")}
                            >
                                <p
                                    className={[
                                        styles.tabTextStyle,
                                        (pathname === item.route ||
                                            pathname === item.route2 ||
                                            pathname === item.route3) &&
                                            styles.activeTabTextStyle,
                                    ].join(" ")}
                                >
                                    {item.title}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div
                        onClick={() => openModal()}
                        className={styles.cartImgViewStyle}
                    >
                        <span className={styles.cartCount}>
                            {cartData?.length > 0 ? cartData?.length : "0"}
                        </span>
                        <img
                            src={shopping}
                            className={styles.shoppingImgStyle}
                            alt="View all"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className={styles.footerSectionStyle}>
                <div className={styles.copyRightsTextViewStyle}>
                    <p className={styles.copyRightsTextStyle}>
                        Copyright © All Rights Reserved 2023 Soop System
                    </p>
                </div>
                <div className={styles.bottomleftSectionStyle}>
                    <div
                        onClick={() => tapOnLogOut()}
                        className={styles.logoutBtnStyle}
                    >
                        <img
                            src={logoutImg}
                            className={styles.logoutImgStyle}
                        />
                        <p className={styles.logoutTextStyle}>Log out</p>
                    </div>
                    <div
                        className={styles.setingsBtnStyle}
                        onClick={() => console.log("cc")}
                    >
                        <p className={styles.settingTextStyle}>Setting</p>
                    </div>
                </div>
            </div>
        );
    };

    let subtitle;

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const renderModal = () => {
        return (
            <div className={styles.customStyles}>
                <Cart onClose={() => setIsOpen(false)} />
            </div>
        );
    };
    return (
        <div className={styles.dashboardContainerStyle}>
            <div className={styles.dashboardSubContainerStyle}>
                {renderTopSection()}
                <div className={styles.outletSectionStyle}>
                    <Outlet />
                </div>
                {renderFooter()}
            </div>
            {modalIsOpen && renderModal()}
        </div>
    );
};
export default DashboardLayout;
