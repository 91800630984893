import { loginApi } from "networking/apis/store/login";
import { registrationApi } from "networking/apis/store/registration";
import React, {
    createContext,
    useState,
    useMemo,
    useCallback,
    useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
// import { adminLoginApi } from "networking/api/admin";
// import { adminDetailsApi } from "networking/api/admin";
// import { employeeDetailsApi } from "networking/api/employee";
import { setAxiosSession } from "utils/auth";
import { getDataFromToken, isTokenExpired } from "utils/jwt";

const AuthContext = createContext();

const AuthProvider = (props) => {
    const navigate = useNavigate();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [isSignUp, setIsSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [storeData, setStoreData] = useState();

    const initialize = useCallback(async () => {
        try {
            const loggedIn = localStorage.getItem("loggedIn");
            console.log("dadasdasd", loggedIn);
            setIsLoggedIn(loggedIn);
            setIsInitialized(true);
            const token = localStorage.getItem("accessToken");
            if (token && isTokenExpired(token)) {
                setAxiosSession(token);
                const tokenData = await getDataFromToken(token);
                // if (tokenData.type === "company" || tokenData.type === "branch") {
                //   let response = await adminDetailsApi(tokenData.id);
                //   if (response.data.type === "success") {
                //     setUser(response.data.data);
                //     setUserType(tokenData.type);
                //     setAxiosSession(token);
                //     setIsLoggedIn(true);
                //   } else {
                //     setAuthError(response.data.message);
                //   }
                // } else {
                //   let response = await employeeDetailsApi(tokenData.id);
                //   if (response.data.type === "success") {
                //     setUser(response.data.data);
                //     setUserType(tokenData.type);
                //     setAxiosSession(token);
                //     setIsLoggedIn(true);
                //   } else {
                //     setAuthError(response.data.message);
                //   }
                // }
                setIsInitialized(true);
            } else {
                setIsInitialized(true);
                // setIsLoggedIn(false);
            }
        } catch (error) {
            setIsInitialized(true);
            setIsLoggedIn(false);
            console.log(error);
            setAuthError(error.message);
        }
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize, storeData]);

    const storeLogin = async (loginData) => {
        console.log("loginData", loginData);
        try {
            setAuthError(null);
            let response = await loginApi(loginData);
            const { type, data, authToken, message } = response.data;
            console.log("data%%", data);
            if (type === "success") {
                localStorage.setItem("accessToken", authToken);
                localStorage.setItem("loggedIn", true);
                localStorage.setItem(
                    "storeData",
                    JSON.stringify(response.data)
                );
                setStoreData(response.data);
                // const tokenData = await getDataFromToken(token);
                setIsLoggedIn(true);
                setUser(data);
                navigate("/products");
            } else {
                setUser(null);
                setAuthError(message);
                setIsLoggedIn(false);
                console.log(response);
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setUser(null);
            setAuthError(error.message);
            setIsLoggedIn(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("storeData")) {
            setStoreData(JSON.parse(localStorage.getItem("storeData")));
        }
    }, [localStorage.getItem("storeData")]);

    const storeRegister = async (signUpData) => {
        console.log(signUpData);
        try {
            setAuthError(null);
            let response = await registrationApi(signUpData);
            const { type, data, token, message } = response.data;
            if (type === "success") {
                console.log("response", response);
                // localStorage.setItem("accessToken", token);
                // const tokenData = await getDataFromToken(token);
                // setIsLoggedIn(true);
                // setUser(data);
                // setUserType(tokenData.type);

                setIsSignUp(true);
            } else {
                setUser(null);
                setAuthError(message);
                setIsLoggedIn(false);
                console.log(response);
                setErrorMessage(response.data.message);
            }
        } catch (error) {
            setUser(null);
            setAuthError(error.message);
            setIsLoggedIn(false);
            setIsSignUp(false);
        }
    };

    const logout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("storeData");
        setUser(null);
        setAxiosSession(null);
        setIsLoggedIn(false);
    };

    const memoizedValue = useMemo(
        () => ({
            isInitialized,
            isLoggedIn,
            user,
            userType,
            authError,
            setAuthError,
            setIsLoggedIn,
            storeLogin,
            storeData,
            setStoreData,
            storeRegister,
            logout,
            isSignUp,
            setIsSignUp,
            errorMessage,
            setErrorMessage,
        }),
        [
            isInitialized,
            isLoggedIn,
            user,
            userType,
            authError,
            setAuthError,
            setIsLoggedIn,
            storeLogin,
            storeData,
            setStoreData,
            storeRegister,
            logout,
            isSignUp,
            setIsSignUp,
            errorMessage,
            setErrorMessage,
        ]
    );

    return (
        <AuthContext.Provider value={memoizedValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
