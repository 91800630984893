import axios from "axios";

const axiosInstance = axios.create({
    baseURL: "http://16.170.203.32:3002/api",

    // "http://16.170.203.32:3002/api",
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(
            (error.response && error.response.data) || "some thing went wrong"
        )
);
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;

// import axios from "axios";
// const axiosInstance = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = JSON.parse(localStorage.getItem("token"));
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// export default axiosInstance;
