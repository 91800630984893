import React, { useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const SelectInput = (props) => {
    const {
        type,
        name,
        value,
        placeholder,
        onChange,
        onKeyDown,
        onFocus,
        onBlur,
        customInputStyles,
        image,
        alt,
        error,
        onClick,
        options,
        searchResultsExist,
        onChangeOptions,
        disabled,
    } = props;
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <div className={styles.inputContainer}>
            <input
                type={type}
                name={name}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                onChange={onChange}
                // onFocus={onFocus}
                // onBlur={onBlur}
                onFocus={(e) => {
                    handleFocus();
                    onFocus && onFocus(e);
                }}
                onBlur={(e) => {
                    handleBlur();
                    onBlur && onBlur(e);
                }}
                className={classNames(styles.inputStyles, customInputStyles)}
                value={value}
                disabled={disabled}
            />

            {image && (
                <div className={styles.inputIcon} onClick={onClick}>
                    <img
                        src={image}
                        alt={alt}
                        className={styles.inputImageStyles}
                    />
                </div>
            )}

            {options && searchResultsExist ? (
                // <div className={styles.optionsContainer}>
                <div
                    className={classNames(styles.optionsContainer, {
                        [styles.noBorder]: !searchResultsExist,
                    })}
                >
                    {options?.map((option, index) => (
                        <div
                            key={index}
                            className={styles.optionItem}
                            onClick={() => onChangeOptions(option)}
                        >
                            {option.seller_name}
                        </div>
                    ))}
                </div>
            ) : null}

            {error && <div className={styles.errorStyles}>{error}</div>}
        </div>
    );
};

export default SelectInput;
