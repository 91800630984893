import Button from "components/button";
import Input from "components/input";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { plus, search } from "resources/images/images";
import styles from "./style.module.css";
import { ProductsData } from "resources/dummyData";
import { useAuthContext } from "hooks/useAuthContext";
import { useAppDataContext } from "hooks/useAppDataContext";
import { getAllProductsApi } from "networking/apis/products/getallproducts";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { getCartProductsApi } from "networking/apis/cart/getcartproducts";
import { useCartDataContext } from "hooks/useCartDataContext";

const Products = () => {
    const navigate = useNavigate();
    const { setIsLoggedIn, isLoggedIn, storeData } = useAuthContext();
    const { handleGetCartProducts } = useCartDataContext();
    // console.log("isLoggedIn", isLoggedIn);
    const { pathname } = useLocation();
    const navigation = useNavigate();
    const [searchText, setSearchText] = useState("");
    const [products, setProducts] = useState();
    const [filteredProducts, setFilteredProducts] = useState(products);
    // const [selectedProduct, setSelectedProduct] = useState("");
    const [addTOCartData, setAddToCartData] = useState();
    // console.log("products&&&&", products);

    useEffect(() => {
        setFilteredProducts(products);
    }, [products]);

    const filterProduct = (e) => {
        setSearchText(e.target.value);
        setFilteredProducts(
            products.filter((product) =>
                JSON.stringify(product)
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
    };

    // const tapOnEditBtn = (data) => {
    //     console.log(data, "ppppppp");
    //     if (data.status === "In store") {
    //         console.log("data", data);
    //     } else {
    //     }
    // };

    const tapOnAddToCartBtn = (data) => {
        if (data.status === "In store") {
            console.log("add to cart", data);
        } else {
        }
    };

    // get all products

    const handleGetAllProducts = async () => {
        try {
            const getAllProductsResponse = await getAllProductsApi();
            if (getAllProductsResponse.data.type === "success") {
                // console.log("getAllProductsResponse", getAllProductsResponse);
                setProducts(getAllProductsResponse.data.data);
            }
        } catch {
            console.log("error inn fetching products");
        }
    };

    useEffect(() => {
        if (storeData) {
            handleGetAllProducts();
        }
    }, [storeData]);

    // add product to the cart

    const handleAddProductToCart = async (product) => {
        try {
            console.log("Selected Product:", product);
            let addProductToCartData = {
                store_id: product.store_id,
                product: product._id,
            };
            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                // setAddToCartData(addProductToCartResponse.data.data);
                alert(addProductToCartResponse.data.message);
                handleGetCartProducts();
                console.log(
                    "addProductToCartResponse",
                    addProductToCartResponse
                );
            }
        } catch {
            console.log("error in adding product to cart");
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <div className={styles.inputSectionStyle}>
                    <Input
                        placeholder="Search here"
                        value={searchText}
                        onChange={(e) => filterProduct(e)}
                        customInputStyle={styles.inputStyle}
                        customInputViewStyle={styles.customInputViewStyle}
                        customRightIconViewStyle={
                            styles.customRightIconViewStyle
                        }
                        rightIcon={search}
                    />
                </div>
                <Button
                    title="Add Product"
                    icon={plus}
                    onClick={() => navigation("/addProduct")}
                    btnStyle={styles.btnViewStyle}
                />
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.headingStyle2}>id</td>
                        <td className={styles.headingStyle2}>Brand</td>
                        <td className={styles.headingStyle2}>Category</td>
                        <td className={styles.headingStyle2}>Model</td>
                        <td className={styles.headingStyle2}>Material</td>
                        <td className={styles.headingStyle2}>Color</td>
                        <td className={styles.headingStyle2}>Size</td>
                        <td className={styles.headingStyle2}>Price</td>
                        <td className={styles.headingStyle2}>Status</td>
                        <td className={styles.headingStyle2}>Created Date</td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {filteredProducts &&
                        filteredProducts.map((item, index) => (
                            <tr
                                key={index}
                                className={styles.assetListDataRowStyle}
                            >
                                <td className={styles.serialNoStyle}>
                                    <p
                                        className={[
                                            styles.serialNoTextStyle,
                                            filteredProducts.length === 1 &&
                                                styles.serialNoTextStyle2,
                                        ].join(" ")}
                                    >
                                        {item.product_uid}
                                    </p>
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.brand}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.category}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.model}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.material}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.colour}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.size}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    {item.price}
                                </td>
                                <td className={styles.assetInfoStyle}>
                                    <p
                                        className={
                                            item.availability_status
                                                ? styles.statusInStoreViewStyle
                                                : styles.statusSoldViewStyle
                                        }
                                    >
                                        {item.availability_status
                                            ? "In store"
                                            : "Sold"}
                                    </p>
                                </td>
                                <td className={styles.createdAtTextStyle}>
                                    {new Date(
                                        item.created_at
                                    ).toLocaleDateString()}
                                </td>
                                <td
                                    className={[
                                        styles.editBtnViewStyle,
                                        item.status === "Sold" &&
                                            styles.disableBtnViewStyle,
                                    ].join(" ")}
                                >
                                    <div
                                        onClick={() =>
                                            navigate("/addProduct", {
                                                state: item,
                                            })
                                        }
                                    >
                                        <p>Edit</p>
                                    </div>
                                </td>
                                <td
                                    className={[
                                        styles.addBtnViewStyle,
                                        item.status === "Sold" &&
                                            styles.disableBtnViewStyle,
                                    ].join(" ")}
                                >
                                    <div
                                        onClick={
                                            // () => tapOnAddToCartBtn(item)
                                            () => handleAddProductToCart(item)
                                        }
                                    >
                                        <p>Add to cart</p>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    };

    const renderTableSection = () => {
        return (
            <div className={styles.tableSectionViewStyle}>
                {renderTableTopSection()}
                <div className={styles.bottomTableViewStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div
            className={[
                pathname === "/products" && styles.containerStyle2,
                styles.containerStyle,
            ].join(" ")}
        >
            {renderTopSection()}
            {renderTableSection()}
        </div>
    );
};
export default Products;
