import React, { useEffect, useRef, useState } from "react";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "components/input";
import {
    dropdownIcon,
    personIcon,
    plus,
    search,
} from "resources/images/images";
import Dropdown from "components/dropdown";
import CheckBox from "components/checkbox";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import {
    Brands,
    Colors,
    Materials,
    Models,
    Products_Categorys,
    SellersData,
    Sizes,
} from "resources/dummyData";
import { useAppDataContext } from "hooks/useAppDataContext";
import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import Popup from "components/popup/popup";
import { registerProductApi } from "networking/apis/products/registerproduct";
import { getStoreMetaDataApi } from "networking/apis/storemeta/storemeta";
import { useAuthContext } from "hooks/useAuthContext";
import { structuringData } from "helpers";
import { editProductApi } from "networking/apis/products/editproduct";
import SelectInput from "components/selectInput/selectInput";
import { IoCaretDownSharp } from "react-icons/io5";
const AddProduct = () => {
    const { storeData } = useAuthContext();
    // console.log("storeData@@@", storeData);
    const navigate = useNavigate();
    const location = useLocation();
    const editProductData = location?.state;
    console.log("productData&&", editProductData);

    const checkBoxOptions = [
        "Consignment deal 50%",
        "Costume consignment deal",
        "Resale",
        "Retail",
    ];
    const [sellerData, setSellerData] = useState();
    const [sellersData, setSellersData] = useState();
    const [searchedSellerData, setSearchedSellerData] = useState([]);
    const [sellerId, setSellerId] = useState("");

    const [productUid, setProductUid] = useState(editProductData?.product_uid);

    const [brand, setBrand] = useState(
        editProductData
            ? { value: editProductData.brand, label: editProductData.brand }
            : { value: "", label: "" }
    );
    // console.log("brand", brand);
    const [brandsData, setBrandsData] = useState();

    const [productCategory, setProductCategory] = useState(
        editProductData
            ? {
                  value: editProductData.category,
                  label: editProductData.category,
              }
            : {
                  value: "",
                  label: "",
              }
    );

    const [productCategorysData, setProductCategorysData] = useState();

    const [consignmentPercentage, setConsignmentPercentage] = useState(
        editProductData
            ? checkBoxOptions.includes(editProductData.consignment)
                ? editProductData.consignment
                : "Costume consignment deal"
            : ""
    );

    const [customConsignmentPercentage, setCustomConsignmentPercentage] =
        useState(editProductData ? editProductData.consignment : "");

    const [price, setPrice] = useState(
        editProductData ? editProductData.price : ""
    );
    const [model, setModel] = useState(
        editProductData
            ? { value: editProductData.model, label: editProductData.model }
            : { value: "", label: "" }
    );
    const [modelsData, setModelsData] = useState();

    const [color, setColor] = useState(
        editProductData
            ? { value: editProductData.colour, label: editProductData.colour }
            : { value: "", label: "" }
    );
    const [colorsData, setColorsData] = useState();

    const [material, setMaterial] = useState(
        editProductData
            ? {
                  value: editProductData.material,
                  label: editProductData.material,
              }
            : { value: "", label: "" }
    );
    const [materialsData, setMaterialsData] = useState();

    const [size, setSize] = useState(
        editProductData
            ? {
                  value: editProductData.size,
                  label: editProductData.size,
              }
            : { value: "", label: "" }
    );
    const [sizes, setSizesData] = useState();

    const [comment, setComment] = useState(
        editProductData ? editProductData.comment : ""
    );
    const [visibility, setVisibility] = useState(false);
    const [customInput, setCustomInput] = useState("");
    const [customInputValue, setCustomInputValue] = useState("");
    const [registeredProductDeatails, setRegisteredProductDetails] =
        useState("");
    const [storeMetaData, setStoreMetaData] = useState(null);
    const [searchResultsExist, setSearchResultsExist] = useState(false);
    const [selectedSellerId, setSelectedSellerId] = useState("");
    const [productStatus, setProductStatus] = useState(
        editProductData ? editProductData?.availability_status : null
    );
    console.log(editProductData?.availability_status);
    const [actionLoading, setActionLoading] = useState(false);

    // console.log("productSattus", productStatus);
    // console.log("storeMetaData$$$", storeMetaData);
    // const brandRef = useRef({ value: "", label: "" });

    /*product status options*/

    const options = [
        { value: "InStore", label: "InStore" },
        { value: "Sold", label: "Sold" },
    ];

    /*handle change the product status*/

    const handleProductStatusSelectedOption = (productStatusSelectedOption) => {
        if (productStatusSelectedOption.value === "InStore") {
            setProductStatus(true);
        } else if (productStatusSelectedOption.value === "Sold") {
            setProductStatus(false);
        }
    };

    /*check box options*/

    const tapOnSave = () => {
        setActionLoading(true);
        setTimeout(() => {
            setActionLoading(false);
            navigate(-1);
        }, 3000);
    };

    const renderLeftSection = () => {
        return (
            <p className={styles.titleTextStyle}>
                {editProductData ? "Edit Porduct" : "Add new product"}
            </p>
        );
    };

    const DropdownIndicator = (props) => {
        return (
            components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                    <SearchIcon />
                </components.DropdownIndicator>
            )
        );
    };

    // const handleSeach = (e) => {
    //     if (e.target.value !== "") {
    //         setSearchedSellerData(
    //             sellersData.filter((item) =>
    //                 item.seller_name
    //                     .toLowerCase()
    //                     .includes(e.target.value.toLowerCase())
    //             )
    //         );
    //     } else {
    //         setSearchedSellerData([]);
    //     }

    //     console.log(
    //         sellersData.filter((item) =>
    //             item.seller_name
    //                 .toLowerCase()
    //                 .includes(e.target.value.toLowerCase())
    //         ),
    //         "pppp"
    //     );
    // };

    const handleSeach = (e) => {
        const searchQuery = e.target.value.toLowerCase();
        const filteredSellers = sellersData.filter((item) =>
            item.seller_name.toLowerCase().includes(searchQuery)
        );
        console.log("filteredSellers", filteredSellers);
        setSellerData();
        if (searchQuery !== "" && filteredSellers.length > 0) {
            setSearchedSellerData(filteredSellers);
            setSearchResultsExist(true);
        } else {
            setSearchedSellerData([]);
            setSearchResultsExist(false);
        }
    };

    const SearchIcon = () => (
        <div className={styles.searchIconViewStyle}>
            {/* <img src={dropdownIcon} className={styles.imgStyle} alt="" /> */}
            <IoCaretDownSharp className={styles.dropdownIcon} />
        </div>
    );

    /*Register a product*/

    // get all seller details

    const handleGetAllSellerDetails = async () => {
        try {
            const getAllSellerDetailsResponse = await getAllSellerDetailsApi();

            setSellersData(getAllSellerDetailsResponse.data.data);
        } catch {
            console.log("error in fetching seller details");
        }
    };

    useEffect(() => {
        handleGetAllSellerDetails();
        handleGetStoreMetaData();
    }, []);

    // register a product

    const handleRegisterProduct = async () => {
        try {
            let productRegisterData = {
                seller_id: sellerData._id,
                brand: brand.value,
                category: productCategory.value,
                // consignment: consignmentPercentage,
                consignment: consignmentPercentage,
                model: model.value,
                colour: color.value,
                material: material.value,
                size: size.value,
                availability_status: productStatus,
                // availability_status: true,
                price: price,
            };
            console.log("productRegisterData", productRegisterData);
            const registerProductResponse = await registerProductApi(
                productRegisterData
            );
            if (registerProductResponse.data.type === "success") {
                setRegisteredProductDetails(registerProductResponse.data.data);
                navigate("/products");
                console.log("registerProductResponse", registerProductResponse);
            } else {
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    // get store meta data

    const handleGetStoreMetaData = async () => {
        try {
            // console.log("storeData.store._id", storeData.store._id);
            const getStoreMetaDataResponse = await getStoreMetaDataApi(
                storeData.store._id
            );
            // console.log("getStoreMetaDataResponse", getStoreMetaDataResponse);

            setBrandsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.brands)
            );

            setProductCategorysData(
                structuringData(
                    getStoreMetaDataResponse.data.storemeta.category
                )
            );

            setModelsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.model)
            );
            setColorsData(
                structuringData(getStoreMetaDataResponse.data.storemeta.color)
            );
            setMaterialsData(
                structuringData(
                    getStoreMetaDataResponse.data.storemeta.material
                )
            );
            setSizesData(
                structuringData(getStoreMetaDataResponse.data.storemeta.size)
            );

            setStoreMetaData(getStoreMetaDataResponse.data.storemeta);
        } catch (error) {
            console.log("error", error);
        }
    };

    // edit product
    // console.log("productStatus", productStatus);
    const handleEditProduct = async () => {
        try {
            const editedProductData = {
                _id: editProductData._id,
                brand: brand.value,
                category: productCategory.value,
                model: model.value,
                colour: color.value,
                material: material.value,
                size: size.value,
                // consignment: consignmentPercentage,
                consignment:
                    consignmentPercentage === "Costume consignment deal"
                        ? editProductData.consignment ===
                          customConsignmentPercentage
                            ? editProductData.consignment
                            : customConsignmentPercentage
                        : consignmentPercentage,
                price: price,
                // comment: comment.value,
                availability_status: productStatus,
            };
            console.log("editedProductData", editedProductData);
            const editedProductResponse = await editProductApi(
                editedProductData
            );
            if (editedProductResponse.data.type === "success") {
                console.log("editedProductResponse", editedProductResponse);
                alert(editedProductResponse.data.message);
                navigate("/products");
            } else {
                console.log("error in editing");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    // Search Seller sections
    const renderSellerSctions = () => {
        return (
            <div className={styles.sellerSearchViewStyle}>
                <p className={styles.lableTextStyle}>Select seller *</p>
                <div className={styles.searchSellerSubViewStyle}>
                    <div className={styles.searchSellerLeftViewStyle}>
                        {/* <Select
                            // defaultValue={seller}
                            options={searchedSellerData.map((item) => ({
                                label: item.seller_name,
                                value: item._id,
                            }))}
                            isClearable={true}
                            isSearchable={true}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            // noOptionsMessage={() => "FOOOO!"}
                            isDisabled={searchedSellerData ? false : true}
                            // onFocus={() => setSearchedSellerData([])}
                            onInputChange={handleSeach}
                            onChange={(data) => {
                                setSellerData(data);
                                setTimeout(() => {
                                    console.log("jskdjgfkds", sellerData);
                                    setSearchedSellerData([]);
                                }, 500);
                            }}
                            className={styles.selecterStyle}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    backgroundColor: "rgba(0, 0, 0, 0.15)",
                                    borderRadius: "7px",
                                    height: "47px",
                                    borderColor: state.isFocused
                                        ? "black"
                                        : "gray",
                                }),
                            }}
                        /> */}

                        <SelectInput
                            name="sellersInput"
                            customInputStyles={styles.sellerInputStyles}
                            type={"text"}
                            placeholder="select"
                            image={search}
                            value={
                                editProductData
                                    ? editProductData?.seller_id?.seller_name
                                    : sellerData?.seller_name
                            }
                            onChange={handleSeach}
                            options={searchedSellerData}
                            searchResultsExist={searchResultsExist}
                            onChangeOptions={(selectedOption) => {
                                setSellerData(selectedOption);
                                setSearchResultsExist(false);
                                setSelectedSellerId(selectedOption.seller_uid);
                            }}
                            disabled={searchedSellerData ? false : true}
                        />
                    </div>

                    <div className={styles.searchSellerRightViewStyle}>
                        {sellerId === "" ? (
                            <Button
                                // title="Add seller"
                                title={
                                    selectedSellerId
                                        ? selectedSellerId
                                        : editProductData
                                        ? editProductData?.seller_id?.seller_uid
                                        : "Add seller"
                                }
                                icon={
                                    selectedSellerId
                                        ? ""
                                        : editProductData
                                        ? ""
                                        : plus
                                }
                                onClick={() =>
                                    selectedSellerId
                                        ? ""
                                        : editProductData
                                        ? ""
                                        : navigate("/addSeller")
                                }
                                btnStyle={styles.btnViewStyle}
                            />
                        ) : (
                            <Button
                                title={sellerId}
                                icon={personIcon}
                                onClick={() => console.log("das")}
                                btnStyle={styles.btnViewStyle}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderBrandSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Brand *"
                    options={brandsData}
                    // value={brandRef.current}
                    value={brand}
                    onChange={(text) => {
                        // brandRef.current = text;
                        setBrand(text);
                        console.log("brand", text);
                    }}
                    containerViewStyle={styles.eachSectionViewStyle}
                    openPopUp={() => {
                        setCustomInput("brand");
                        setVisibility(true);
                    }}
                />
                <div className={styles.eachSectionViewStyle}>
                    <p className={styles.lableTextStyle}>Product number</p>
                    {/* <div className={styles.productNumberViewStyle}> */}
                    {/* <p className={styles.productNumberTextStyle}>
                            {editProductData ? editProductData.product_uid : ""}
                        </p> */}
                    <Input
                        placeholder="Enter uid..."
                        value={productUid}
                        onChange={(e) => setProductUid(e.target.value)}
                        customInputStyle={styles.productUidStyle}
                    />
                    {/* </div> */}
                </div>
            </div>
        );
    };

    const renderPopUpSection = () => {
        return (
            <Popup
                show={visibility}
                title={popUpTitle()}
                value={customInputValue}
                onChange={(e) => {
                    setCustomInputValue(e.target.value);
                }}
                // addedProductDeatail,
                name={popInputName()}
                placeholder={popPlaceHolderName()}
                lableText={popUpLabelText()}
                closeHandler={() => {
                    setVisibility(false);
                    setCustomInput("");
                }}
                addProductValue={(e) => {
                    handleCustomeInput(e);
                    setTimeout(() => {
                        setVisibility(false);
                    }, 200);
                }}
            />
        );
    };
    const popUpTitle = () => {
        if (customInput === "brand") {
            return "Brand";
        } else if (customInput === "productCategory") {
            return "Product Category";
        } else if (customInput === "color") {
            return "Color";
        } else if (customInput === "model") {
            return "Model";
        } else if (customInput === "material") {
            return "Material";
        } else if (customInput === "size") {
            return "Size";
        } else if (customInput === "consignment") {
            return "consignment (%)";
        }
    };

    const popInputName = () => {
        if (customInput === "brand") {
            return "brand";
        } else if (customInput === "productCategory") {
            return "productCategory";
        } else if (customInput === "color") {
            return "color";
        } else if (customInput === "model") {
            return "model";
        } else if (customInput === "material") {
            return "Material";
        } else if (customInput === "size") {
            return "Material";
        } else if (customInput === "consignment") {
            return "ConsignmentPercentage";
        }
    };

    const popPlaceHolderName = () => {
        if (customInput === "brand") {
            return "brand";
        } else if (customInput === "productCategory") {
            return "product Category";
        } else if (customInput === "color") {
            return "color";
        } else if (customInput === "model") {
            return "model";
        } else if (customInput === "material") {
            return "material";
        } else if (customInput === "size") {
            return "size";
        } else if (customInput === "consignment") {
            return "consignmentPercentage";
        }
    };

    const popUpLabelText = () => {
        if (customInput === "brand") {
            return "Enter Brand Name";
        } else if (customInput === "productCategory") {
            return "Enter Product Category";
        } else if (customInput === "color") {
            return "Enter Color";
        } else if (customInput === "model") {
            return "Enter Model";
        } else if (customInput === "material") {
            return "Enter Material";
        } else if (customInput === "size") {
            return "Enter Size";
        } else if (customInput === "consignment") {
            return "Enter Consignment Percentage";
        }
    };

    const handleCustomeInput = () => {
        // validate custom input value

        // Validate the customInputValue

        console.log("customIm", customInputValue);
        if (customInput === "brand") {
            setBrandsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            // brandRef.current = {
            //     label: customInputValue,
            //     value: customInputValue,
            // };
            setBrand({ label: customInputValue, value: customInputValue });
        } else if (customInput === "productCategory") {
            setProductCategorysData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setProductCategory({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "color") {
            setColorsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setColor({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "model") {
            setModelsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setModel({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "material") {
            setMaterialsData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setMaterial({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "size") {
            setSizesData((prev) => [
                ...prev,
                { label: customInputValue, value: customInputValue },
            ]);
            setCustomInputValue("");
            setSize({
                label: customInputValue,
                value: customInputValue,
            });
        } else if (customInput === "consignment") {
            const parsedInput = parseFloat(customInputValue);
            if (isNaN(parsedInput) || parsedInput < 0 || parsedInput > 100) {
                // Invalid input, show an error message or take appropriate action
                alert("Please enter a valid percentage between 0 and 100.");
            } else {
                setCustomConsignmentPercentage(parsedInput + "%");
                setConsignmentPercentage("Costume consignment deal");
            }
        }
    };

    const renderCategorySection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Product category *"
                    options={productCategorysData}
                    // value={productCategory}
                    value={productCategory}
                    onChange={(text) => setProductCategory(text)}
                    containerViewStyle={styles.eachSectionViewStyle}
                    openPopUp={() => {
                        setCustomInput("productCategory");
                        setVisibility(true);
                    }}
                />
                <div className={styles.checkboxSectionViewStyle}>
                    <div>
                        <p className={styles.lableTextStyle}>
                            Sales agreement *
                        </p>
                        <div className={styles.checkboxSubSectionViewStyle}>
                            {checkBoxOptions.map((item, index) => (
                                <div key={index}>
                                    <CheckBox
                                        // lableText={item}
                                        // checked={consignmentPercentage === item}
                                        lableText={
                                            item === "Costume consignment deal"
                                                ? `${item} (${customConsignmentPercentage})`
                                                : item
                                        }
                                        checked={
                                            consignmentPercentage === item
                                                ? true
                                                : consignmentPercentage !==
                                                      item &&
                                                  consignmentPercentage === item
                                        }
                                        onClick={() => {
                                            if (index === 1) {
                                                setCustomInput("consignment");
                                                setVisibility(true);
                                            } else {
                                                setVisibility(false);
                                                setConsignmentPercentage(item);
                                            }
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.statusSectionStyle}>
                        <p className={styles.lableTextStyle}>Status</p>
                        <Select
                            // defaultValue={productStatus}
                            defaultValue={
                                productStatus === true
                                    ? options.find(
                                          (option) => option.value === "InStore"
                                      )
                                    : options.find(
                                          (option) => option.value === "Sold"
                                      )
                            }
                            onChange={handleProductStatusSelectedOption}
                            options={options}
                            // isClearable={true}
                            isSearchable={true}
                            components={{
                                IndicatorSeparator: () => null,
                                DropdownIndicator,
                            }}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    background:
                                        "linear-gradient(0deg, rgba(71, 199, 26, 0.40) 0%, rgba(71, 199, 26, 0.40) 100%), rgba(0, 0, 0, 0.00)",
                                    borderRadius: "16px",
                                    minHeight: "24px",
                                    width: "106px",
                                    borderColor: state.isFocused
                                        ? "black"
                                        : "gray",
                                }),
                            }}
                        />

                        {/* <div className={styles.statusBtnViewStyle}>
                            <p className={styles.statusTextStyle}>Select</p>
                            <div
                                className={styles.statusDropDownImgViewStyle}
                                onClick={() => setProductStatus(!productStatus)}
                            >
                                <img
                                    src={dropdownIcon}
                                    className={styles.statusDropDownImgStyle}
                                    alt=""
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    };

    const renderModelAndPriceSection = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <Dropdown
                    lableText="Model"
                    options={modelsData}
                    // value={model}
                    value={model}
                    onChange={(text) => {
                        setModel(text);
                        console.log("Model", text);
                    }}
                    openPopUp={() => {
                        setCustomInput("model");
                        setVisibility(true);
                    }}
                    containerViewStyle={styles.eachSectionViewStyle}
                />
                <div className={styles.eachSectionViewStyle}>
                    <Input
                        placeholder="Enter price..."
                        lableText="Price *"
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.customInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderOtherSections = () => {
        return (
            <div className={styles.sectionViewStyle}>
                <div className={styles.otherLeftSectionStyle}>
                    <Dropdown
                        lableText="Colour"
                        options={colorsData}
                        // value={color}
                        value={color}
                        onChange={(text) => {
                            setColor(text);
                            console.log("Colour", text);
                        }}
                        openPopUp={() => {
                            setCustomInput("color");
                            setVisibility(true);
                        }}
                    />
                    <Dropdown
                        lableText="Material"
                        options={materialsData}
                        // value={material}
                        value={material}
                        onChange={(text) => {
                            setMaterial(text);
                            console.log("Material", text);
                        }}
                        openPopUp={() => {
                            setCustomInput("material");
                            setVisibility(true);
                        }}
                    />
                    <Dropdown
                        lableText="Size"
                        options={sizes}
                        // value={size}
                        value={size}
                        onChange={(text) => {
                            setSize(text);
                            console.log("Size", text);
                        }}
                        openPopUp={() => {
                            setCustomInput("size");
                            setVisibility(true);
                        }}
                    />
                </div>
                <div className={styles.eachSectionViewStyle}>
                    <TextArea
                        placeholder="Enter comment..."
                        lableText="Comment *"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        lableTextStyle={styles.lableTextStyle}
                        customInputStyle={styles.commentInputViewStyle}
                    />
                </div>
            </div>
        );
    };

    const renderBtnSection = () => {
        return (
            <div className={styles.saveBtnSectionViewStyles}>
                <Button
                    title={
                        actionLoading
                            ? "Loading ...."
                            : "Save product" && editProductData
                            ? "Edit Product"
                            : "Save Product"
                    }
                    // onClick={() => tapOnSave()}
                    onClick={
                        editProductData
                            ? handleEditProduct
                            : handleRegisterProduct
                    }
                    btnStyle={styles.saveBtnStyle}
                />
                <p className={styles.createdTextStyle}>Created: 02.03.2023</p>
            </div>
        );
    };
    const renderFormSection = () => {
        return (
            <div className={styles.formViewStyle}>
                {renderSellerSctions()}
                {renderBrandSection()}
                {renderCategorySection()}
                {renderModelAndPriceSection()}
                {renderOtherSections()}
                {renderBtnSection()}
            </div>
        );
    };

    return (
        <div className={styles.containerStyle}>
            <div className={styles.leftSectionStyle}>
                {renderLeftSection()}
                {renderFormSection()}
                {renderPopUpSection()}
            </div>
            <div className={styles.rightSectionStyle}>
                <Button
                    title="Back"
                    onClick={() => navigate(-1)}
                    btnStyle={styles.customBtnStyle}
                />
            </div>
        </div>
    );
};
export default AddProduct;
