import { getAllSellerDetailsApi } from "networking/apis/seller/getallsellerdetails";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
import React, { createContext, useEffect, useState } from "react";

export const SellerDataContext = createContext();

export const SellerDataProvider = (props) => {
    const [allSellers, setAllSellers] = useState();
    // console.log("allsellers", allSellers)

    useEffect(() => {
        handleGetAllSellerDeatils();
    }, []);

    // get  all seller details

    const handleGetAllSellerDeatils = async () => {
        try {
            const getAllSellersResponse = await getAllSellerDetailsApi();
            if (getAllSellersResponse) {
                // console.log("getAllSellersResponse", getAllSellersResponse);
                setAllSellers(getAllSellersResponse.data.data);
            }
        } catch {
            console.log("error in fetching sellers details");
        }
    };

    return (
        <SellerDataContext.Provider
            value={{ allSellers, handleGetAllSellerDeatils }}
        >
            {props.children}
        </SellerDataContext.Provider>
    );
};
