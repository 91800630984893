import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthGuard from "../guards/authguard";
import GuestGuard from "../guards/guestguard";
import DashboardLayout from "../layouts/dashboard";
import Login from "../pages/login";
import Products from "../pages/products";
import Sellers from "pages/sellers";
import SignUp from "../pages/signup";
import Reports from "pages/reports";
import AddProduct from "pages/addproduct";
import AddSeller from "pages/addseller";
import Profile from "pages/profile";
import Landing from "pages/landing";
const AppRoutes = () => {
    return useRoutes([
        {
            path: "/SignUp",
            element: <SignUp />,
        },
        {
            path: "/",
            element: <Login />,
        },
        {
            path: "",
            children: [
                {
                    element: (
                        <AuthGuard>
                            <DashboardLayout />
                        </AuthGuard>
                    ),
                    children: [
                        {
                            element: <Navigate to={"/products"} replace />,
                            index: true,
                        },
                        { path: "/products", element: <Products /> },
                        { path: "/sellers", element: <Sellers /> },
                        { path: "/reports", element: <Reports /> },
                        { path: "/addProduct", element: <AddProduct /> },
                        { path: "/addSeller", element: <AddSeller /> },
                        { path: "/profile", element: <Profile /> },
                    ],
                },
                {
                    path: "landing",
                    element: (
                        <GuestGuard>
                            <Landing />
                        </GuestGuard>
                    ),
                },
                {
                    path: "signup",
                    element: <SignUp />,
                },
                {
                    path: "login",
                    element: <Login />,
                },
            ],
        },
    ]);
};
export default AppRoutes;
