import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "providers/authprovider";
import { AppDataProvider } from "providers/appdataprovider";
import { CartDataProvider } from "providers/cartdataprovider";
import { SellerDataProvider } from "providers/sellerdataprovider";
function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <AppDataProvider>
                    <SellerDataProvider>
                        <CartDataProvider>
                            <AppRoutes />
                        </CartDataProvider>
                    </SellerDataProvider>
                </AppDataProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
