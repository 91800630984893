import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Button from "components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { personIcon } from "resources/images/images";
import TextArea from "components/textarea";
import { ProductsData } from "resources/dummyData";
import { useAuthContext } from "hooks/useAuthContext";
import { getSellerDetailsApi } from "networking/apis/seller/getsellerdetails";
import { addProductToCartApi } from "networking/apis/cart/addproducttocart";
import { useCartDataContext } from "hooks/useCartDataContext";
const Profile = () => {
    const navigate = useNavigate();
    const { storeData } = useAuthContext();
    const { handleGetCartProducts } = useCartDataContext();
    // get seller details

    const location = useLocation();
    const sellerDetails = location.state;
    // console.log("sellerDetails", sellerDetails);

    // usestates

    const [sellerAndProductDetails, setSellerAndProductDetails] = useState();
    // console.log("sellerAndProductDetails", sellerAndProductDetails);

    const tapOnEditBtn = (data) => {
        if (data.status === "In store") {
            console.log("data", data);
        } else {
        }
    };

    const tapOnAddToCartBtn = (data) => {
        if (data.status === "In store") {
            console.log("add to cart", data);
        } else {
        }
    };

    // get sellerDeatils and product details

    const handleGetSellerDeatailsAndProducts = async () => {
        try {
            const getSellerDetailsAndProductsResponse =
                await getSellerDetailsApi(sellerDetails?._id);
            if ((getSellerDetailsAndProductsResponse.data.type = "success")) {
                // console.log(
                //     "getSellerDetailsAndProductsResponse",
                //     getSellerDetailsAndProductsResponse
                // );
                setSellerAndProductDetails(
                    getSellerDetailsAndProductsResponse.data.data[0]
                );
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        handleGetSellerDeatailsAndProducts();
    }, []);

    // add product to the cart

    const handleAddProductToCart = async (_id) => {
        try {
            // console.log("Selected Product:", product);
            let addProductToCartData = {
                store_id: sellerAndProductDetails.store_id,
                product: _id,
            };
            console.log("addProductToCartData", addProductToCartData);
            const addProductToCartResponse = await addProductToCartApi(
                addProductToCartData
            );
            if (addProductToCartResponse.data.type === "success") {
                // setAddToCartData(addProductToCartResponse.data.data);
                alert(addProductToCartResponse.data.message);
                handleGetCartProducts();
                console.log(
                    "addProductToCartResponse",
                    addProductToCartResponse
                );
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.topSectionStyle}>
                <p className={styles.titleTextStyle}>Profile</p>
                <div className={styles.backBtnViewStyle}>
                    <Button
                        title="Back"
                        onClick={() => navigate(-1)}
                        btnStyle={styles.backBtnStyle}
                    />
                </div>
            </div>
        );
    };

    const renderProfileDetailsLeftSection = () => {
        return (
            <div className={styles.profileDetailsSectionLeftStyle}>
                <div>
                    <p className={styles.lableTextStyle}>Name</p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.seller_name}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>Phone</p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.phone_number}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>E-mail</p>
                    <p className={styles.textStyle}>{sellerDetails?.email}</p>
                </div>
            </div>
        );
    };

    const renderProfileDetailsRightSection = () => {
        return (
            <div className={styles.profileDetailsSectionLeftStyle}>
                <div>
                    <p className={styles.lableTextStyle}>Member since</p>
                    <p className={styles.textStyle}>22.03.2023</p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>Reg number</p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.reg_number}
                    </p>
                </div>
                <div>
                    <p className={styles.lableTextStyle}>Bank number</p>
                    <p className={styles.textStyle}>
                        {sellerDetails?.bank_account}
                    </p>
                </div>
            </div>
        );
    };

    const renderProfileTopSection = () => {
        return (
            <div className={styles.profileTopSectionStyle}>
                <div className={styles.profileTopSectionLeftStyle}>
                    <Button
                        title={sellerDetails?.seller_uid}
                        disabled={true}
                        icon={personIcon}
                        customImgStyle={styles.profileImgStyle}
                        btnStyle={styles.profileIdSectionStyle}
                    />
                    <div className={styles.profileDetailsSectionStyle}>
                        {renderProfileDetailsLeftSection()}
                        {renderProfileDetailsRightSection()}
                    </div>
                </div>
                <div className={styles.profileTopSectionRightStyle}>
                    <div className={styles.commentSectionStyle}>
                        <p className={styles.lableTextStyle}>Comment</p>
                        <TextArea
                            // value={comment}
                            disabled={true}
                            customInputStyle={styles.commentViewStyle}
                        />
                    </div>
                    <div>
                        <Button
                            title="Edit"
                            // onClick={() => console.log("xxx")}
                            onClick={() => {
                                // setIsSellerEdit(true);
                                navigate("/addSeller", {
                                    state: sellerAndProductDetails,
                                });
                            }}
                            btnStyle={styles.editBtnStyle}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderTableTopSection = () => {
        return (
            <table>
                <thead>
                    <tr className={styles.headingStyle}>
                        <td className={styles.headingStyle2}>id</td>
                        <td className={styles.headingStyle2}>Brand</td>
                        <td className={styles.headingStyle2}>Category</td>
                        <td className={styles.headingStyle2}>Model</td>
                        <td className={styles.headingStyle2}>Material</td>
                        <td className={styles.headingStyle2}>Color</td>
                        <td className={styles.headingStyle2}>Size</td>
                        <td className={styles.headingStyle2}>Price</td>
                        <td className={styles.headingStyle2}>Status</td>
                        <td className={styles.headingStyle2}>Created Date</td>
                        <td className={styles.headingStyle2}></td>
                        <td className={styles.headingStyle2}></td>
                    </tr>
                </thead>
            </table>
        );
    };

    const renderTableBottomSection = () => {
        return (
            <table>
                <tbody>
                    {sellerAndProductDetails?.products &&
                        sellerAndProductDetails?.products?.map(
                            (item, index) => (
                                <tr
                                    key={index}
                                    className={styles.assetListDataRowStyle}
                                >
                                    {/* {console.log("item", item)} */}
                                    <td className={styles.serialNoStyle}>
                                        <p
                                            className={[
                                                styles.serialNoTextStyle,
                                                ProductsData.length === 1 &&
                                                    styles.serialNoTextStyle2,
                                            ].join(" ")}
                                        >
                                            {item.product_uid}
                                        </p>
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.brand}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.category}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.model}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.material}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.colour}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.size}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        {item.price}
                                    </td>
                                    <td className={styles.assetInfoStyle}>
                                        <p
                                            className={
                                                item.status === "Sold"
                                                    ? styles.statusSoldViewStyle
                                                    : styles.statusInStoreViewStyle
                                            }
                                        >
                                            {item.availability_status === true
                                                ? "InStore"
                                                : "Sold"}
                                        </p>
                                    </td>
                                    <td className={styles.createdAtTextStyle}>
                                        {new Date(
                                            item.created_at
                                        ).toLocaleDateString()}
                                    </td>
                                    <td
                                        className={[
                                            styles.editBtnViewStyle,
                                            item.status === "Sold" &&
                                                styles.disableBtnViewStyle,
                                        ].join(" ")}
                                    >
                                        <div
                                            onClick={() => {
                                                tapOnEditProduct(item);
                                            }}
                                        >
                                            <p>Edit</p>
                                        </div>
                                    </td>
                                    <td
                                        className={[
                                            styles.addBtnViewStyle,
                                            item.status === "Sold" &&
                                                styles.disableBtnViewStyle,
                                        ].join(" ")}
                                    >
                                        <div
                                            onClick={() =>
                                                handleAddProductToCart(item._id)
                                            }
                                        >
                                            <p>Add to cart</p>
                                        </div>
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
        );
    };

    const tapOnEditProduct = (selectedProduct) => {
        let sellerData = {
            seller_uid: sellerAndProductDetails.seller_uid,
            seller_name: sellerAndProductDetails.seller_name,
        };
        selectedProduct.seller_id = sellerData;
        navigate("/addProduct", { state: selectedProduct });
    };

    const renderProfileBottomSection = () => {
        return (
            <div className={styles.profileBottomSectionStyle}>
                {renderTableTopSection()}
                <div className={styles.profileBottomSubSectionStyle}>
                    {renderTableBottomSection()}
                </div>
            </div>
        );
    };
    const renderProfileSection = () => {
        return (
            <div className={styles.profileSectionStyle}>
                <div className={styles.profileSubSectionStyle}>
                    {renderProfileTopSection()}
                    {renderProfileBottomSection()}
                </div>
            </div>
        );
    };
    return (
        <div className={styles.containerStyle}>
            {renderTopSection()}
            {renderProfileSection()}
        </div>
    );
};
export default Profile;
