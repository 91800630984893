// Registration api

export const registration = "store/register";

// Login Api

export const logIn = "store/login";

/*products*/

// get all products
export const getAllProducts = "product/all";

//get products of a seller

export const gerProductsOfSeller = "product/get/{id}";
// register a product

export const registerProduct = "product/register";

// edit a product
export const editProduct = "product/update";

// delete a product
export const deleteProduct = "product/delete/{id}";

// purchase a product

export const purchasedProducts = "product/purchase";

/*seller*/

export const getAllSellerDetails = "seller/all";

export const addSeller = "seller/register";

export const getSellerDetails = "seller/get/{id}";

export const editSeller = "seller/edit";

/*cart*/

// add to cart
export const addToCart = "cart/add";

// get cart products
export const getCartProducts = "cart/get/{id}";

// remove cart products
export const removeProductFromCart = "cart/remove";

// store meta data

export const getStoreMetaData = "storemeta/{id}";
