import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { closeIcon } from "resources/images/images";
import Button from "components/button";
import TextArea from "components/textarea";
import styles from "./style.module.css";
import { useCartDataContext } from "hooks/useCartDataContext";
import { purchaseProductApi } from "networking/apis/products/purchasedproduct";
const Cart = (props) => {
    const { onClose = () => {} } = props;
    const { cartData, handleGetCartProducts } = useCartDataContext();
    // console.log("cartDtaa%%", cartData);
    const [purchaseMethod, setPurchaseMethod] = useState();
    // console.log("purchaseMethod", purchaseMethod);

    // get cart products while cart open

    useEffect(() => {
        handleGetCartProducts();
    }, []);

    // caluculate the total price:

    const caluculateTotalPrice = (cartData) => {
        return cartData.reduce(
            (total, item) =>
                total + parseFloat(item.product.price.replace(/,/g, "")),
            0
        );
    };

    const formatPriceWithCommas = (price) => {
        return price.toLocaleString("en-US");
    };

    // purchase product

    const handlePurchaseProducts = async () => {
        try {
            let cartProductIds = cartData?.map((item) => item.product._id);
            let purchaseMethodData = purchaseMethod;
            const requestData = {
                purchasedProducts: cartProductIds,
                payment_method: purchaseMethodData,
            };
            const getPurchaseProductResponse = await purchaseProductApi(
                requestData
            );
            if (getPurchaseProductResponse.data.type === "success") {
                console.log(
                    "getPurchaseProductResponse",
                    getPurchaseProductResponse
                );
                alert("products purchased");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const renderTopSection = () => {
        return (
            <div className={styles.cartHeaserStyle}>
                <p className={styles.titleTextStyle}>Shopping cart</p>
                <div className={styles.closeImgViewStyle}>
                    <img
                        src={closeIcon}
                        className={styles.closeImgStyle}
                        onClick={onClose}
                    />
                </div>
            </div>
        );
    };

    const renderMiddleSection = () => {
        return <div className={styles.middleSectionStyle}></div>;
    };

    const renderEmptySection = () => {
        return (
            <div className={styles.emptySectionStyle}>
                <p className={styles.emptyTextStyle}>No Data Available</p>
            </div>
        );
    };

    const renderCartProductsSection = () => {
        return (
            <div className={styles.cartProductsContainerStyle}>
                <div className={styles.cartProductDetailsMainBlockStyle}>
                    <div className={styles.cartProductDetailsBlockStyle}>
                        {cartData &&
                            cartData.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            styles.cartProductsDetailsSubBlockStyle
                                        }
                                    >
                                        <div
                                            className={
                                                styles.cartProductDescLeftBlockStyle
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.cartProductDateStyle
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles.cartProductDateDescStyle
                                                    }
                                                >
                                                    {/* Date: 23.06.2023 */}
                                                    Date:
                                                    {new Date(
                                                        item.product.created_at
                                                    ).toLocaleDateString()}
                                                </p>
                                            </div>

                                            <div
                                                className={
                                                    styles.cartProductsDetailsBlockStyle
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.cartProductsDetailsStyle
                                                    }
                                                >
                                                    <p
                                                        className={
                                                            styles.cartProductSerialNumberStyle
                                                        }
                                                    >
                                                        {index + 1}.
                                                    </p>

                                                    <p
                                                        className={
                                                            styles.cartProductDescriptionStyle
                                                        }
                                                    >
                                                        {item.product.brand};
                                                        {item.product.category},
                                                        {item.product.model},
                                                        {item.product.material},
                                                        {item.product.colour},
                                                        {item.product.size}
                                                    </p>
                                                </div>
                                                <p
                                                    className={
                                                        styles.cartProductPrice
                                                    }
                                                >
                                                    {item.product.price}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    <div className={styles.totalPriceRightBlockStyle}>
                        <p className={styles.sumTextStyle}>
                            Sum
                            <span className={styles.sumPriceStyle}>
                                {formatPriceWithCommas(
                                    caluculateTotalPrice(cartData)
                                )}
                            </span>
                            kk
                        </p>
                    </div>
                </div>

                <div className={styles.paymentAndPurchaseBtnStyle}>
                    <div className={styles.cartPaymentBlockStyle}>
                        <div className={styles.paymentButtonsStyle}>
                            <Button
                                title="Cash"
                                // onClick={() => navigation("/addProduct")}
                                onClick={() => setPurchaseMethod("Cash")}
                                btnStyle={
                                    purchaseMethod === "Cash"
                                        ? styles.activeBtnStyle
                                        : styles.inActiveBtnStyle
                                }
                            />
                            <Button
                                title="MobilePay"
                                // onClick={() => navigation("/addProduct")}
                                onClick={() => setPurchaseMethod("MobilePay")}
                                btnStyle={
                                    purchaseMethod === "MobilePay"
                                        ? styles.activeBtnStyle
                                        : styles.inActiveBtnStyle
                                }
                            />
                            <Button
                                title="Card"
                                onClick={() => setPurchaseMethod("Card")}
                                btnStyle={
                                    purchaseMethod === "Card"
                                        ? styles.activeBtnStyle
                                        : styles.inActiveBtnStyle
                                }
                            />
                            <Button
                                title="Other"
                                // onClick={() => navigation("/addProduct")}
                                onClick={() => setPurchaseMethod("Other")}
                                btnStyle={
                                    purchaseMethod === "Other"
                                        ? styles.activeBtnStyle
                                        : styles.inActiveBtnStyle
                                }
                            />
                        </div>
                        <div className={styles.commentInputStyle}>
                            <TextArea
                                // value={comment}
                                placeholder="Comment"
                                onClick={() => setPurchaseMethod("Comment")}
                                // disabled={true}
                                customInputStyle={styles.commentViewStyle}
                            />
                        </div>
                    </div>

                    <div className={styles.confirmPurchaseBlockStyle}>
                        <Button
                            title="Confirm Purchase"
                            // icon={plus}
                            // onClick={() => navigation("/addProduct")}
                            btnStyle={styles.confirmPurchaseBtnStyle}
                            onClick={handlePurchaseProducts}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.cartViewStyle}>
            {renderTopSection()}
            {renderMiddleSection()}
            {/* {renderEmptySection()} */}
            {cartData.length > 0
                ? renderCartProductsSection()
                : renderEmptySection()}
        </div>
    );
};

Cart.propTypes = {
    onClose: PropTypes.func,
};
export default Cart;
